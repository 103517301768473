import logo from './logo.svg';
import './grid-gallery.css';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import About from './Pages/About';
import Gallery from './Pages/Gallery';
import TalentPage from './Pages/Talent';

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/:id" element={<TalentPage />} />
      </Routes>
    </>
  );
}

export default App;
