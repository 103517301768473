import { collection, getDocs } from "firebase/firestore";
import { db } from '../firebase';

export const TalentData = async () => {
    //Get Talent data
    const talents = [];
    await getDocs(collection(db, "talent"))
        .then((querySnapshot) => {
            querySnapshot.forEach(talent => {
                talents.push({
                    id: talent.id,
                    ...talent.data()
                })
            });
        });

    return talents;
};

export const BrandData = async () => {
    //Get Brands data
    const brands = [];
    await getDocs(collection(db, "brands"))
        .then((querySnapshot) => {
            querySnapshot.forEach(brand => {
                brands.push({
                    id: brand.id,
                    ...brand.data()
                })
            });
        });

    return brands;
};

export const AboutData = async () => {
    //Get about data
    const about = [];
    await getDocs(collection(db, "texts"))
        .then((querySnapshot) => {
            querySnapshot.forEach(text => {
                about.push({
                    id: text.id,
                    ...text.data()
                })
            });
        });

    return about;
};

export const GalleryData = async () => {
    //Get about data
    const gallery = [];
    await getDocs(collection(db, "gallery"))
        .then((querySnapshot) => {
            querySnapshot.forEach(images => {
                gallery.push({
                    id: images.id,
                    ...images.data()
                })
            });
        });

    return gallery;
};