import React, { useState } from 'react';
import $ from "jquery";

export const Buttons = (props) => {
    const [slider, setSlider] = useState(0)

    const changeFont = (num) => {
        if (num === 0) {
            $('body').css({ 'font-family': '"orpheuspro", serif' })
        } else if (num === 1) {
            $('body').css('font-family', '"neue-haas-unica", sans-serif')
        }
    }

    return (
        <div id='buttons'>
            <div id='fontChanger' style={{ bottom: props.bottom }}>
                <p onClick={() => { changeFont(0) }}>Orpheus Pro</p>
                <p onClick={() => { changeFont(1) }}>Unica</p>
            </div>
        </div >
    );
};