import React, { useState, useEffect, } from 'react';
import MarkdownView from 'react-showdown';
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from '../firebase';
import { NavBar } from '../Components/NavBar.jsx'
import { Buttons } from '../Components/Buttons.jsx'

const TalentPage = () => {
    const [talent, setTalent] = useState(null);
    const [error, setError] = useState(null);
    const { id } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            const docRef = doc(db, "talent", id);
            try {
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    setTalent(docSnap.data());
                } else {
                    setError(error);
                }

            } catch (error) {
                console.error("Error getting document:", error);
            }
        }

        fetchData();
    }, []);

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <section id="Talent" className="section">
            <div id='talentInfo'>
                <h1 id='talentName'>{talent ? talent.name : ''}</h1>
                <div id='talentText'>{
                    talent ? <MarkdownView
                        markdown={talent.text}
                        options={{ tables: true, emoji: true }}
                        key={'0'}
                    /> : ''
                }</div>
                <div id='talentContacts'>
                    <a id='Instagram' className='talentContact' target='_blank' href={talent ? talent.instagram : ''} style={(talent && talent.instagram) ? { margin: '0 10px' } : { margin: '0' }}>{(talent && talent.instagram) ? 'Instagram' : ''}</a>
                    <span>{(talent && talent.website) ? '—' : ''}</span>
                    <a id='Website' className='talentContact' target='_blank' href={talent ? talent.website : ''} style={(talent && talent.website) ? { margin: '0 10px' } : { margin: '0' }}>{(talent && talent.website) ? 'Website' : ''}</a>
                    <span>{(talent && talent.spotify) ? '—' : ''}</span>
                    <a id='Spotify' className='talentContact' target='_blank' href={talent ? talent.spotify : ''} style={(talent && talent.spotify) ? { margin: '0 10px' } : { margin: '0' }}>{(talent && talent.spotify) ? 'Spotify' : ''}</a>
                </div>
                <div id='talentImages'>
                    {talent && talent.talent_images.map((talentImage, index) => (
                        <img key={index} className='talentImage' src={talentImage} />
                    ))}
                </div>
            </div>
            <NavBar page={'talent'} />
            <Buttons bottom={10} />
        </section >
    );
};

export default TalentPage;