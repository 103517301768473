import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TalentData, BrandData, AboutData } from '../Components/GetData.jsx'
import $ from "jquery";
import { Spotify } from "react-spotify-embed";
import { NavBar } from '../Components/NavBar.jsx'
import { Buttons } from '../Components/Buttons.jsx'


const Talent = (props) => {
    const navigate = useNavigate();
    let numRow

    props.mobile ? numRow = 3 : numRow = 6

    const navigateTalent = () => {
        navigate(`${props.talent.id}`, { talentInfo: props.talent })
    }

    let divider
    if (props.index + 1 != props.talents.length) {
        if ((props.index + 1) % numRow != 0) {
            divider = <p className="divider">—</p>
        } else {
            divider = ''
        }
    } else {
        divider = ''
    }

    return (
        <>
            <h1 onClick={navigateTalent}>{props.talent ? props.talent.name : ''}</h1>
            {divider}
        </>
    )
}

const Brand = (props) => {
    const openWebsite = () => {
        props.brandData.website && window.open(props.brandData.website)
    }

    return (
        <img className='ticker-img' src={props.brandData.logo} onClick={openWebsite} />
    )
}

const Home = () => {
    const [talents, setTalent] = useState([]);
    const [brandsOne, setBrandsOne] = useState([]);
    const [brandsTwo, setBrandsTwo] = useState([]);
    const [about, setAbout] = useState([]);
    const [spotify, setSpotify] = useState(false)
    const [spotifyhover, setSpotifyHover] = useState(false)
    const [mobile, setMobile] = useState(false)

    const toggleSpotify = () => {
        if (spotify) {
            $('#spotifyContainer').css({ 'bottom': '-390px', 'background-color': 'black' })
            $('#spotifyArrow').css('transform', 'scaleY(100%)')
            setSpotify(false)
        } else {
            $('#spotifyContainer').css({ 'bottom': '0', 'background-color': 'green' })
            $('#spotifyArrow').css('transform', 'scaleY(-100%)')
            setSpotify(true)
        }
    }

    const hoverSpotify = () => {
        if (spotifyhover) {
            $('#spotifyOpen').css('color', 'white')
            $('#spotifyArrow').css('filter', 'grayscale(100%) brightness(5)')
            setSpotifyHover(false)
        } else {
            if (spotify) {
                $('#spotifyOpen').css('color', 'white')
                $('#spotifyArrow').css('filter', 'grayscale(100%) brightness(5)')
            } else {
                $('#spotifyOpen').css('color', 'green')
                $('#spotifyArrow').css('filter', 'grayscale(0%) brightness(1)')
            }
            setSpotifyHover(true)
        }
    }

    const fetchData = async () => {

        const talents = await TalentData();
        const brands = await BrandData();
        const about = await AboutData();

        talents.sort((a, b) => a.order - b.order);

        setTalent([...talents])
        setBrandsOne(brands.slice(0, brands.length / 2))
        setBrandsTwo(brands.slice(brands.length / 2))
        setAbout([...about])
    }

    useEffect(() => {
        fetchData();
        $('.ticker-track-bottom').each(function () {
            $(this).addClass('brandsAnimationBottom')
        })
        $('.ticker-track-top').each(function () {
            $(this).addClass('brandsAnimationTop')
        })
        window.innerWidth <= 600 && setMobile(true)
    }, []);

    return (
        <section id="Home" className="section">
            <div id='talentContainer'>
                <h1 id='talentTitle'>Talent</h1>
                <div id='talent'>
                    {talents.length > 0 && talents.map((talent, index) => (
                        <Talent key={talent.id} talent={talent} talents={talents} index={index} mobile={mobile} />
                    ))}
                </div>
            </div>
            <div id='brands'>
                <div id='brandsTop'>
                    <div className='ticker-track-top'>
                        {brandsTwo.length > 0 && brandsTwo.map((brand) => (
                            <Brand key={brand.id} brandData={brand} />
                        ))}
                    </div>
                    <div className='ticker-track-top'>
                        {brandsTwo.length > 0 && brandsTwo.map((brand) => (
                            <Brand key={brand.id} brandData={brand} />
                        ))}
                    </div>
                </div>
                <div id='brandsBottom'>
                    <div className='ticker-track-bottom'>
                        {brandsOne.length > 0 && brandsOne.map((brand) => (
                            <Brand key={brand.id} brandData={brand} />
                        ))}
                    </div>
                    <div className='ticker-track-bottom'>
                        {brandsOne.length > 0 && brandsOne.map((brand) => (
                            <Brand key={brand.id} brandData={brand} />
                        ))}
                    </div>
                </div>
            </div>
            <div id='spotifyContainer'>
                <div id='spotifyOpen' onClick={toggleSpotify} onMouseEnter={hoverSpotify} onMouseLeave={hoverSpotify}>
                    <img id='spotifyArrow' src='./textures/listenArrow.png' />
                    <h1>Listen to Buried Spotify</h1>
                </div>
                <div id='spotifyIntegration'>
                    <Spotify width='100%' link={about[0] ? about[0].spotify_player : 'https://open.spotify.com/playlist/7qcQ1O3Ptn3kbxiD1cBoX9?si=57a51d4e389c4f18'} />
                </div>
            </div>
            <NavBar page={'home'} />
        </section>
    );
};

export default Home;