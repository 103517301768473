import React, { useState, useEffect } from 'react';
import MarkdownView from 'react-showdown';
import { AboutData } from '../Components/GetData.jsx'
import { NavBar } from '../Components/NavBar.jsx'
import { Buttons } from '../Components/Buttons.jsx'


const About = () => {

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([]);

    const openLink = (url, email) => {
        email ? window.open('mailto:' + url) : window.open(url)
    }

    const fetchData = async () => {
        setLoading(true)

        const data = await AboutData()

        setData([...data])
        setLoading(false)
    }

    useEffect(() => {
        fetchData();
    }, []);

    let aboutData = data[0];

    return (
        <section id="About" className="section">
            <NavBar page={'about'} />
            <Buttons bottom={10} />
            <div id="aboutText">{
                aboutData ? <MarkdownView
                    markdown={aboutData.about}
                    options={{ tables: true, emoji: true }}
                    key={aboutData}
                /> : ''
            }</div>
            <div id='contactInfo'>
                <div id="Email" className='contacts' onClick={() => { openLink(aboutData ? aboutData.email : '', true) }}><img className='aboutIcon' src='./textures/email.png' /></div>
                <div id="Instagram" className='contacts' onClick={() => { openLink(aboutData ? aboutData.instagram : '', false) }}><img className='aboutIcon' src='./textures/instagram.png' /></div>
                <div id="Linkedin" className='contacts' onClick={() => { openLink(aboutData ? aboutData.linkedin : '', false) }}><img className='aboutIcon' src='./textures/linkedin.png' /></div>
            </div>
            <p className="credits">Website by <a href="https://www.instagram.com/sylathas/" target='_blank'>Sylathas</a></p>
        </section>
    );
};

export default About;