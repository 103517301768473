import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { AboutData } from './GetData.jsx'


export const NavBar = (props) => {
    const [spotify, setSpotify] = useState('')

    const imageStyle = {
        backgroundImage: 'url("./Logo.png")'
    }

    const spotifyStyle = {
        backgroundImage: 'url("./SpotifyLogo.png")'
    }

    const fetchData = async () => {

        const about = await AboutData();

        setSpotify(about[0].spotify_player)
    }

    useEffect(() => {
        fetchData();
    }, []);

    const openSpotify = () => {
        window.open(spotify)
    }

    return (
        <>
            <div id="navBarHome">
                <NavLink to="/About">
                    <div className="linkNav" id='homeAbout'>
                        About
                    </div>
                </NavLink>
                <NavLink to="/">
                    <div id="homeLogo" style={imageStyle}></div>
                </NavLink>
                <NavLink to="/Gallery">
                    <div className="linkNav" id="homeGallery">
                        Gallery
                    </div>
                </NavLink>
            </div>
            <div id="navBarMobile">
                <NavLink to="/" style={(props.page === 'home' || props.page === 'talent') ? { display: 'none' } : { 'display': 'block' }}>
                    <div className="linkNavMobile">
                        Talent
                    </div>
                </NavLink>
                <NavLink to="/About" style={props.page === 'about' ? { display: 'none' } : { 'display': 'block' }}>
                    <div className="linkNavMobile">
                        About
                    </div>
                </NavLink>
                <NavLink to="/Gallery" style={props.page === 'gallery' ? { display: 'none' } : { 'display': 'block' }}>
                    <div className="linkNavMobile">
                        Gallery
                    </div>
                </NavLink>
            </div>
            <div className="linkNavMobile" id='linkSpotifyMobile' onClick={openSpotify} style={spotifyStyle}></div>
        </>
    );
};