import { getFirestore } from "firebase/firestore";
import { initializeApp } from "firebase/app";


// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBd6bQAXrmEB5zYoeDEVU8NqQPvGsNaW_M",
    authDomain: "buried-96900.firebaseapp.com",
    projectId: "buried-96900",
    storageBucket: "buried-96900.appspot.com",
    messagingSenderId: "315135399141",
    appId: "1:315135399141:web:f8c6dfb1a7929380f8700c"
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
