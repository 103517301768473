import React, { useState, useEffect, useContext } from 'react';
import { GalleryData } from '../Components/GetData.jsx'
import { NavBar } from '../Components/NavBar.jsx'
import { Buttons } from '../Components/Buttons.jsx'

const MoveContext = React.createContext(null);

const Image = (props) => {
    const { setCursorText } = useContext(MoveContext);
    const [mobile, setMobile] = useState(false)

    let imgName = props.url.slice(77, -53).replace('_', ' ')
    imgName = imgName.substring(0, imgName.indexOf("."))

    const changeText = () => {
        setCursorText(imgName)
    }

    const removeText = () => {
        setCursorText('')
    }

    useEffect(() => {
        window.innerWidth <= 600 && setMobile(true)
    }, []);

    return (
        <>
            <img src={props.url} onMouseEnter={changeText} onMouseLeave={removeText}></img>
            {mobile ? <p className='imageText'>{imgName}</p> : null}
        </>
    )
}

const Gallery = () => {

    const [data, setData] = useState([]);
    const [cursorText, setCursorText] = useState();

    const [x, setX] = useState()
    const [y, setY] = useState()

    const cursorTextStyle = {
        left: x,
        top: y
    }

    const fetchData = async () => {

        const data = await GalleryData()

        setData([...data])
    }

    useEffect(() => {
        fetchData();
        const update = (e) => {
            setX(e.pageX)
            setY(e.pageY + 10)
        }
        window.addEventListener('mousemove', update)
        window.addEventListener('touchmove', update)
        return () => {
            window.removeEventListener('mousemove', update)
            window.removeEventListener('touchmove', update)
        }
    },
        [setX, setY]
    )

    return (
        <MoveContext.Provider value={{ cursorText: cursorText, setCursorText: setCursorText }}>
            <section id="Gallery" className="section">
                <div className="gg-container">
                    <div className="gg-box">
                        {data.length > 0 && data[0].gallery_images.map((image, index) => (
                            <Image key={index} url={image} />
                        ))}
                    </div>
                </div>
                <NavBar page={'gallery'} />
                <Buttons bottom={10} />
                <p id='cursor' style={cursorTextStyle}>{cursorText}</p>
            </section>
        </MoveContext.Provider>
    );
};

export default Gallery;